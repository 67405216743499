'use strict';

var cov_1dgaxaktwy = function () {
  var path = 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\app\\term\\term.js',
      hash = '40852b80394b540d3c6a9d431c99e177dc425243',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\app\\term\\term.js',
    statementMap: {
      '0': {
        start: {
          line: 3,
          column: 4
        },
        end: {
          line: 3,
          column: 42
        }
      },
      '1': {
        start: {
          line: 7,
          column: 0
        },
        end: {
          line: 12,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 2
          },
          end: {
            line: 2,
            column: 3
          }
        },
        loc: {
          start: {
            line: 2,
            column: 16
          },
          end: {
            line: 4,
            column: 3
          }
        },
        line: 2
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TermController = function TermController() {
  _classCallCheck(this, TermController);

  cov_1dgaxaktwy.f[0]++;
  cov_1dgaxaktwy.s[0]++;

  this.text = 'My brand new component!';
};

cov_1dgaxaktwy.s[1]++;


angular.module('app').component('term', {
  templateUrl: 'app/term/term.html',
  controller: TermController
});