'use strict';

var cov_ilb4hgdoa = function () {
  var path = 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\routes.js',
      hash = '270e2ad4eec64db25fb4029aebc6d9eb21f95301',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\routes.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 32,
          column: 24
        }
      },
      '1': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 7,
          column: 118
        }
      },
      '2': {
        start: {
          line: 8,
          column: 4
        },
        end: {
          line: 8,
          column: 27
        }
      },
      '3': {
        start: {
          line: 9,
          column: 4
        },
        end: {
          line: 21,
          column: 7
        }
      },
      '4': {
        start: {
          line: 10,
          column: 6
        },
        end: {
          line: 20,
          column: 7
        }
      },
      '5': {
        start: {
          line: 11,
          column: 8
        },
        end: {
          line: 19,
          column: 11
        }
      },
      '6': {
        start: {
          line: 12,
          column: 10
        },
        end: {
          line: 12,
          column: 50
        }
      },
      '7': {
        start: {
          line: 13,
          column: 10
        },
        end: {
          line: 13,
          column: 46
        }
      },
      '8': {
        start: {
          line: 14,
          column: 10
        },
        end: {
          line: 14,
          column: 51
        }
      },
      '9': {
        start: {
          line: 15,
          column: 10
        },
        end: {
          line: 15,
          column: 52
        }
      },
      '10': {
        start: {
          line: 16,
          column: 10
        },
        end: {
          line: 16,
          column: 56
        }
      },
      '11': {
        start: {
          line: 17,
          column: 10
        },
        end: {
          line: 17,
          column: 60
        }
      },
      '12': {
        start: {
          line: 18,
          column: 10
        },
        end: {
          line: 18,
          column: 49
        }
      },
      '13': {
        start: {
          line: 30,
          column: 4
        },
        end: {
          line: 30,
          column: 43
        }
      },
      '14': {
        start: {
          line: 37,
          column: 2
        },
        end: {
          line: 37,
          column: 63
        }
      },
      '15': {
        start: {
          line: 38,
          column: 2
        },
        end: {
          line: 38,
          column: 62
        }
      },
      '16': {
        start: {
          line: 39,
          column: 2
        },
        end: {
          line: 39,
          column: 52
        }
      },
      '17': {
        start: {
          line: 40,
          column: 2
        },
        end: {
          line: 40,
          column: 36
        }
      },
      '18': {
        start: {
          line: 41,
          column: 2
        },
        end: {
          line: 43,
          column: 5
        }
      },
      '19': {
        start: {
          line: 45,
          column: 2
        },
        end: {
          line: 138,
          column: 3
        }
      },
      '20': {
        start: {
          line: 51,
          column: 10
        },
        end: {
          line: 53,
          column: 13
        }
      },
      '21': {
        start: {
          line: 52,
          column: 12
        },
        end: {
          line: 52,
          column: 32
        }
      },
      '22': {
        start: {
          line: 62,
          column: 10
        },
        end: {
          line: 64,
          column: 13
        }
      },
      '23': {
        start: {
          line: 63,
          column: 12
        },
        end: {
          line: 63,
          column: 31
        }
      },
      '24': {
        start: {
          line: 73,
          column: 10
        },
        end: {
          line: 75,
          column: 13
        }
      },
      '25': {
        start: {
          line: 74,
          column: 12
        },
        end: {
          line: 74,
          column: 31
        }
      },
      '26': {
        start: {
          line: 112,
          column: 10
        },
        end: {
          line: 114,
          column: 13
        }
      },
      '27': {
        start: {
          line: 113,
          column: 12
        },
        end: {
          line: 113,
          column: 32
        }
      },
      '28': {
        start: {
          line: 132,
          column: 10
        },
        end: {
          line: 134,
          column: 13
        }
      },
      '29': {
        start: {
          line: 133,
          column: 12
        },
        end: {
          line: 133,
          column: 32
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 7
          },
          end: {
            line: 4,
            column: 8
          }
        },
        loc: {
          start: {
            line: 4,
            column: 109
          },
          end: {
            line: 28,
            column: 3
          }
        },
        line: 4
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 9,
            column: 47
          },
          end: {
            line: 9,
            column: 48
          }
        },
        loc: {
          start: {
            line: 9,
            column: 53
          },
          end: {
            line: 21,
            column: 5
          }
        },
        line: 9
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 11,
            column: 51
          },
          end: {
            line: 11,
            column: 52
          }
        },
        loc: {
          start: {
            line: 11,
            column: 62
          },
          end: {
            line: 19,
            column: 9
          }
        },
        line: 11
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 29,
            column: 35
          },
          end: {
            line: 29,
            column: 36
          }
        },
        loc: {
          start: {
            line: 29,
            column: 62
          },
          end: {
            line: 31,
            column: 3
          }
        },
        line: 29
      },
      '4': {
        name: 'routesConfig',
        decl: {
          start: {
            line: 36,
            column: 9
          },
          end: {
            line: 36,
            column: 21
          }
        },
        loc: {
          start: {
            line: 36,
            column: 134
          },
          end: {
            line: 139,
            column: 1
          }
        },
        line: 36
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 50,
            column: 59
          },
          end: {
            line: 50,
            column: 60
          }
        },
        loc: {
          start: {
            line: 50,
            column: 88
          },
          end: {
            line: 54,
            column: 9
          }
        },
        line: 50
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 51,
            column: 97
          },
          end: {
            line: 51,
            column: 98
          }
        },
        loc: {
          start: {
            line: 51,
            column: 108
          },
          end: {
            line: 53,
            column: 11
          }
        },
        line: 51
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 61,
            column: 42
          },
          end: {
            line: 61,
            column: 43
          }
        },
        loc: {
          start: {
            line: 61,
            column: 71
          },
          end: {
            line: 65,
            column: 9
          }
        },
        line: 61
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 62,
            column: 74
          },
          end: {
            line: 62,
            column: 75
          }
        },
        loc: {
          start: {
            line: 62,
            column: 84
          },
          end: {
            line: 64,
            column: 11
          }
        },
        line: 62
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 72,
            column: 43
          },
          end: {
            line: 72,
            column: 44
          }
        },
        loc: {
          start: {
            line: 72,
            column: 72
          },
          end: {
            line: 76,
            column: 9
          }
        },
        line: 72
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 73,
            column: 75
          },
          end: {
            line: 73,
            column: 76
          }
        },
        loc: {
          start: {
            line: 73,
            column: 85
          },
          end: {
            line: 75,
            column: 11
          }
        },
        line: 73
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 111,
            column: 71
          },
          end: {
            line: 111,
            column: 72
          }
        },
        loc: {
          start: {
            line: 111,
            column: 114
          },
          end: {
            line: 115,
            column: 9
          }
        },
        line: 111
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 112,
            column: 100
          },
          end: {
            line: 112,
            column: 101
          }
        },
        loc: {
          start: {
            line: 112,
            column: 111
          },
          end: {
            line: 114,
            column: 11
          }
        },
        line: 112
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 130,
            column: 68
          },
          end: {
            line: 130,
            column: 69
          }
        },
        loc: {
          start: {
            line: 130,
            column: 111
          },
          end: {
            line: 135,
            column: 9
          }
        },
        line: 130
      },
      '14': {
        name: '(anonymous_14)',
        decl: {
          start: {
            line: 132,
            column: 90
          },
          end: {
            line: 132,
            column: 91
          }
        },
        loc: {
          start: {
            line: 132,
            column: 101
          },
          end: {
            line: 134,
            column: 11
          }
        },
        line: 132
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 10,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 10,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        }, {
          start: {
            line: 10,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        }],
        line: 10
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_ilb4hgdoa.s[0]++;
angular.module('app')
// eslint-disable-next-line max-params
.run(function (cfCryptoHttpInterceptor, $rootScope, $location, myVars, $http, localStorageService, $transitions) {
  cov_ilb4hgdoa.f[0]++;
  cov_ilb4hgdoa.s[1]++;

  // run local thi dung cai nay
  // $rootScope.apiOpen = 'http://localhost:7400/rest/open/';
  $rootScope.apiOpen = $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/api/rest/open/';
  cov_ilb4hgdoa.s[2]++;
  $rootScope.msisdn = '';
  cov_ilb4hgdoa.s[3]++;
  $transitions.onStart({ to: '*', from: '*' }, function () {
    cov_ilb4hgdoa.f[1]++;
    cov_ilb4hgdoa.s[4]++;

    if (myVars.login !== 1) {
      cov_ilb4hgdoa.b[0][0]++;
      cov_ilb4hgdoa.s[5]++;

      return $http.post($rootScope.apiOpen).then(function (results) {
        cov_ilb4hgdoa.f[2]++;
        cov_ilb4hgdoa.s[6]++;

        $rootScope.msisdn = results.data.msisdn;
        cov_ilb4hgdoa.s[7]++;
        myVars.msisdn = results.data.msisdn;
        cov_ilb4hgdoa.s[8]++;
        myVars.checkMember = results.data.active;
        cov_ilb4hgdoa.s[9]++;
        myVars.packageid = results.data.packageid;
        cov_ilb4hgdoa.s[10]++;
        $rootScope.packageid = results.data.packageid;
        cov_ilb4hgdoa.s[11]++;
        $rootScope.packagefree = results.data.packagefree;
        cov_ilb4hgdoa.s[12]++;
        $rootScope.isSub = results.data.active;
      });
    } else {
      cov_ilb4hgdoa.b[0][1]++;
    }
  });

  // $rootScope.apiSec = $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/api/rest/secure/';
  // $rootScope.apiUrl = $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/api/';

  // $rootScope.apiOpen = 'http://vt.mynhanplus.vn:8400/rest/open/';
  // $rootScope.apiOpen = $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/api/rest/open/';
}).run(['cfCryptoHttpInterceptor', function (cfCryptoHttpInterceptor) {
  cov_ilb4hgdoa.f[3]++;
  cov_ilb4hgdoa.s[13]++;

  cfCryptoHttpInterceptor.logging = true; // the default value is false. True will log the decrypted and the encrypted values to the console.
}]).config(routesConfig);

/** @ngInject */
// eslint-disable-next-line max-params
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider, localStorageServiceProvider, $urlMatcherFactoryProvider) {
  cov_ilb4hgdoa.f[4]++;
  cov_ilb4hgdoa.s[14]++;

  localStorageServiceProvider.setStorageType('sessionStorage');
  cov_ilb4hgdoa.s[15]++;
  localStorageServiceProvider.setStorageCookie(0, '/', false);
  cov_ilb4hgdoa.s[16]++;
  $locationProvider.html5Mode(true).hashPrefix('!');
  cov_ilb4hgdoa.s[17]++;
  $urlRouterProvider.otherwise('/');
  cov_ilb4hgdoa.s[18]++;
  $urlMatcherFactoryProvider.type('SlashFix', {
    raw: true
  });

  cov_ilb4hgdoa.s[19]++;
  $stateProvider.state('app', {
    url: '/',
    component: 'app',
    resolve: {
      clipsData: ['$rootScope', '$http', '$stateParams', function ($rootScope, $http) {
        cov_ilb4hgdoa.f[5]++;
        cov_ilb4hgdoa.s[20]++;

        return $http.get($rootScope.apiOpen + 'article/getFeatured/' + $rootScope.msisdn).then(function (results) {
          cov_ilb4hgdoa.f[6]++;
          cov_ilb4hgdoa.s[21]++;

          return results.data;
        });
      }]
    }
  }).state('rule', {
    url: '/rule',
    component: 'rule',
    resolve: {
      ruleData: ['$rootScope', '$http', function ($rootScope, $http) {
        cov_ilb4hgdoa.f[7]++;
        cov_ilb4hgdoa.s[22]++;

        return $http.get($rootScope.apiOpen + 'content/find/rule').then(function (result) {
          cov_ilb4hgdoa.f[8]++;
          cov_ilb4hgdoa.s[23]++;

          return result.data;
        });
      }]
    }
  }).state('about', {
    url: '/about',
    component: 'about',
    resolve: {
      aboutData: ['$rootScope', '$http', function ($rootScope, $http) {
        cov_ilb4hgdoa.f[9]++;
        cov_ilb4hgdoa.s[24]++;

        return $http.get($rootScope.apiOpen + 'content/find/about').then(function (result) {
          cov_ilb4hgdoa.f[10]++;
          cov_ilb4hgdoa.s[25]++;

          return result.data;
        });
      }]
    }
  }).state('dstt', {
    url: '/danh-sach-trung-thuong',
    component: 'dstt'
  }).state('account', {
    url: '/tai-khoan',
    component: 'account'
  }).state('login', {
    url: '/dang-nhap',
    component: 'login'
  }).state('download', {
    url: '/download/:clipid',
    component: 'download'
  }).state('policy', {
    url: '/the-le',
    component: 'policy'
  }).state('term', {
    url: '/term',
    component: 'term'
  }).state('policies', {
    url: '/policies',
    component: 'policies'
  }).state('chitiet', {
    url: '/chi-tiet/:title/:articleid',
    component: 'arcview',
    resolve: {
      articleData: ['$rootScope', '$http', '$stateParams', 'myVars', function ($rootScope, $http, $stateParams) {
        cov_ilb4hgdoa.f[11]++;
        cov_ilb4hgdoa.s[26]++;

        return $http.get($rootScope.apiOpen + 'article/getDetail/' + $stateParams.articleid).then(function (results) {
          cov_ilb4hgdoa.f[12]++;
          cov_ilb4hgdoa.s[27]++;

          return results.data;
        });
      }]
    }
  }).state('danhmuc', {
    url: '/danh-muc/:title/:categorieid',
    component: 'categories'
  }).state('search', {
    url: '/tim-kiem/:title',
    component: 'search'
  }).state('views', {
    url: '/view/:title/:clipid',
    component: 'views',
    resolve: {
      clipData: ['$rootScope', '$http', '$stateParams', 'myVars', function ($rootScope, $http, $stateParams) {
        cov_ilb4hgdoa.f[13]++;
        cov_ilb4hgdoa.s[28]++;

        // return $http.get($rootScope.apiOpen + 'clips/find', config).then(results => {
        return $http.get($rootScope.apiOpen + 'clips/find/' + $stateParams.clipid).then(function (results) {
          cov_ilb4hgdoa.f[14]++;
          cov_ilb4hgdoa.s[29]++;

          return results.data;
        });
      }]
    }
  });
}