'use strict';

var cov_1esermgif3 = function () {
  var path = 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\app\\title.js',
      hash = 'c277562216d861e4fb15d668d64bf9f3648d80b8',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\app\\title.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 4,
          column: 30
        }
      },
      '1': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 5,
          column: 38
        }
      },
      '2': {
        start: {
          line: 6,
          column: 15
        },
        end: {
          line: 6,
          column: 16
        }
      },
      '3': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 15,
          column: 6
        }
      },
      '4': {
        start: {
          line: 8,
          column: 6
        },
        end: {
          line: 8,
          column: 13
        }
      },
      '5': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 11,
          column: 7
        }
      },
      '6': {
        start: {
          line: 10,
          column: 8
        },
        end: {
          line: 10,
          column: 15
        }
      },
      '7': {
        start: {
          line: 12,
          column: 6
        },
        end: {
          line: 14,
          column: 9
        }
      },
      '8': {
        start: {
          line: 13,
          column: 8
        },
        end: {
          line: 13,
          column: 48
        }
      },
      '9': {
        start: {
          line: 16,
          column: 4
        },
        end: {
          line: 23,
          column: 6
        }
      },
      '10': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 22,
          column: 9
        }
      },
      '11': {
        start: {
          line: 19,
          column: 8
        },
        end: {
          line: 21,
          column: 9
        }
      },
      '12': {
        start: {
          line: 20,
          column: 10
        },
        end: {
          line: 20,
          column: 50
        }
      },
      '13': {
        start: {
          line: 26,
          column: 0
        },
        end: {
          line: 31,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 3,
            column: 3
          }
        },
        loc: {
          start: {
            line: 3,
            column: 58
          },
          end: {
            line: 24,
            column: 3
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 7,
            column: 20
          },
          end: {
            line: 7,
            column: 21
          }
        },
        loc: {
          start: {
            line: 7,
            column: 41
          },
          end: {
            line: 15,
            column: 5
          }
        },
        line: 7
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 12,
            column: 88
          },
          end: {
            line: 12,
            column: 89
          }
        },
        loc: {
          start: {
            line: 12,
            column: 99
          },
          end: {
            line: 14,
            column: 7
          }
        },
        line: 12
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 16,
            column: 17
          },
          end: {
            line: 16,
            column: 18
          }
        },
        loc: {
          start: {
            line: 16,
            column: 38
          },
          end: {
            line: 23,
            column: 5
          }
        },
        line: 16
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 17,
            column: 63
          },
          end: {
            line: 17,
            column: 64
          }
        },
        loc: {
          start: {
            line: 17,
            column: 74
          },
          end: {
            line: 22,
            column: 7
          }
        },
        line: 17
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 9,
            column: 6
          },
          end: {
            line: 11,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 9,
            column: 6
          },
          end: {
            line: 11,
            column: 7
          }
        }, {
          start: {
            line: 9,
            column: 6
          },
          end: {
            line: 11,
            column: 7
          }
        }],
        line: 9
      },
      '1': {
        loc: {
          start: {
            line: 19,
            column: 8
          },
          end: {
            line: 21,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 19,
            column: 8
          },
          end: {
            line: 21,
            column: 9
          }
        }, {
          start: {
            line: 19,
            column: 8
          },
          end: {
            line: 21,
            column: 9
          }
        }],
        line: 19
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var RighController =
// eslint-disable-next-line max-params
function RighController($scope, $rootScope, $http, $window, myVars) {
  _classCallCheck(this, RighController);

  cov_1esermgif3.f[0]++;
  cov_1esermgif3.s[0]++;

  $scope.checkinfo = myVars;
  cov_1esermgif3.s[1]++;
  $scope.msisdn = $rootScope.msisdn;
  var time = (cov_1esermgif3.s[2]++, 0);
  cov_1esermgif3.s[3]++;
  $scope.dangky = function (packageid) {
    cov_1esermgif3.f[1]++;
    cov_1esermgif3.s[4]++;

    time++;
    cov_1esermgif3.s[5]++;
    if (time > 1) {
      cov_1esermgif3.b[0][0]++;
      cov_1esermgif3.s[6]++;

      return;
    } else {
      cov_1esermgif3.b[0][1]++;
    }
    cov_1esermgif3.s[7]++;
    $http.post($rootScope.apiOpen + 'dangky/' + $scope.msisdn + '/' + packageid).then(function (results) {
      cov_1esermgif3.f[2]++;
      cov_1esermgif3.s[8]++;

      $window.location = results.data.message;
    });
  };
  cov_1esermgif3.s[9]++;
  $scope.huy = function (packageid) {
    cov_1esermgif3.f[3]++;
    cov_1esermgif3.s[10]++;

    $http.post($rootScope.apiOpen + 'huy/' + packageid).then(function (results) {
      cov_1esermgif3.f[4]++;
      cov_1esermgif3.s[11]++;

      // eslint-disable-next-line no-alert
      if (confirm('Bạn có chắc muốn hủy dịch vụ?')) {
        cov_1esermgif3.b[1][0]++;
        cov_1esermgif3.s[12]++;

        $window.location = results.data.message;
      } else {
        cov_1esermgif3.b[1][1]++;
      }
    });
  };
};

cov_1esermgif3.s[13]++;

angular.module('app').component('fountainTitle', {
  templateUrl: 'app/title.html',
  controller: RighController
});