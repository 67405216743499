'use strict';

var cov_2bn7jyq2r4 = function () {
  var path = 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\app\\views\\views.js',
      hash = '03bebc9bc03a60c022d6423b1906ac520e9f5c6a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\app\\views\\views.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 17
        },
        end: {
          line: 4,
          column: 21
        }
      },
      '1': {
        start: {
          line: 6,
          column: 20
        },
        end: {
          line: 6,
          column: 54
        }
      },
      '2': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 7,
          column: 59
        }
      },
      '3': {
        start: {
          line: 8,
          column: 4
        },
        end: {
          line: 8,
          column: 30
        }
      },
      '4': {
        start: {
          line: 9,
          column: 4
        },
        end: {
          line: 9,
          column: 39
        }
      },
      '5': {
        start: {
          line: 10,
          column: 4
        },
        end: {
          line: 18,
          column: 6
        }
      },
      '6': {
        start: {
          line: 11,
          column: 6
        },
        end: {
          line: 11,
          column: 41
        }
      },
      '7': {
        start: {
          line: 12,
          column: 24
        },
        end: {
          line: 12,
          column: 46
        }
      },
      '8': {
        start: {
          line: 13,
          column: 6
        },
        end: {
          line: 17,
          column: 7
        }
      },
      '9': {
        start: {
          line: 14,
          column: 8
        },
        end: {
          line: 14,
          column: 28
        }
      },
      '10': {
        start: {
          line: 15,
          column: 13
        },
        end: {
          line: 17,
          column: 7
        }
      },
      '11': {
        start: {
          line: 16,
          column: 8
        },
        end: {
          line: 16,
          column: 26
        }
      },
      '12': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 27
        }
      },
      '13': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 20,
          column: 30
        }
      },
      '14': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 34,
          column: 6
        }
      },
      '15': {
        start: {
          line: 22,
          column: 22
        },
        end: {
          line: 28,
          column: 24
        }
      },
      '16': {
        start: {
          line: 29,
          column: 6
        },
        end: {
          line: 33,
          column: 9
        }
      },
      '17': {
        start: {
          line: 30,
          column: 8
        },
        end: {
          line: 30,
          column: 25
        }
      },
      '18': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 32,
          column: 25
        }
      },
      '19': {
        start: {
          line: 36,
          column: 4
        },
        end: {
          line: 51,
          column: 6
        }
      },
      '20': {
        start: {
          line: 37,
          column: 22
        },
        end: {
          line: 45,
          column: 27
        }
      },
      '21': {
        start: {
          line: 46,
          column: 6
        },
        end: {
          line: 50,
          column: 9
        }
      },
      '22': {
        start: {
          line: 47,
          column: 8
        },
        end: {
          line: 47,
          column: 25
        }
      },
      '23': {
        start: {
          line: 49,
          column: 8
        },
        end: {
          line: 49,
          column: 25
        }
      },
      '24': {
        start: {
          line: 52,
          column: 4
        },
        end: {
          line: 56,
          column: 6
        }
      },
      '25': {
        start: {
          line: 53,
          column: 6
        },
        end: {
          line: 55,
          column: 9
        }
      },
      '26': {
        start: {
          line: 54,
          column: 8
        },
        end: {
          line: 54,
          column: 48
        }
      },
      '27': {
        start: {
          line: 157,
          column: 0
        },
        end: {
          line: 165,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 3,
            column: 3
          }
        },
        loc: {
          start: {
            line: 3,
            column: 158
          },
          end: {
            line: 154,
            column: 3
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 10,
            column: 19
          },
          end: {
            line: 10,
            column: 20
          }
        },
        loc: {
          start: {
            line: 10,
            column: 31
          },
          end: {
            line: 18,
            column: 5
          }
        },
        line: 10
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 21,
            column: 24
          },
          end: {
            line: 21,
            column: 25
          }
        },
        loc: {
          start: {
            line: 21,
            column: 38
          },
          end: {
            line: 34,
            column: 5
          }
        },
        line: 21
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 29,
            column: 35
          },
          end: {
            line: 29,
            column: 36
          }
        },
        loc: {
          start: {
            line: 29,
            column: 41
          },
          end: {
            line: 31,
            column: 7
          }
        },
        line: 29
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 31,
            column: 9
          },
          end: {
            line: 31,
            column: 10
          }
        },
        loc: {
          start: {
            line: 31,
            column: 15
          },
          end: {
            line: 33,
            column: 7
          }
        },
        line: 31
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 36,
            column: 22
          },
          end: {
            line: 36,
            column: 23
          }
        },
        loc: {
          start: {
            line: 36,
            column: 36
          },
          end: {
            line: 51,
            column: 5
          }
        },
        line: 36
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 46,
            column: 35
          },
          end: {
            line: 46,
            column: 36
          }
        },
        loc: {
          start: {
            line: 46,
            column: 41
          },
          end: {
            line: 48,
            column: 7
          }
        },
        line: 46
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 48,
            column: 9
          },
          end: {
            line: 48,
            column: 10
          }
        },
        loc: {
          start: {
            line: 48,
            column: 15
          },
          end: {
            line: 50,
            column: 7
          }
        },
        line: 48
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 52,
            column: 20
          },
          end: {
            line: 52,
            column: 21
          }
        },
        loc: {
          start: {
            line: 52,
            column: 41
          },
          end: {
            line: 56,
            column: 5
          }
        },
        line: 52
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 53,
            column: 66
          },
          end: {
            line: 53,
            column: 67
          }
        },
        loc: {
          start: {
            line: 53,
            column: 77
          },
          end: {
            line: 55,
            column: 7
          }
        },
        line: 53
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 13,
            column: 6
          },
          end: {
            line: 17,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 13,
            column: 6
          },
          end: {
            line: 17,
            column: 7
          }
        }, {
          start: {
            line: 13,
            column: 6
          },
          end: {
            line: 17,
            column: 7
          }
        }],
        line: 13
      },
      '1': {
        loc: {
          start: {
            line: 13,
            column: 10
          },
          end: {
            line: 13,
            column: 112
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 13,
            column: 10
          },
          end: {
            line: 13,
            column: 23
          }
        }, {
          start: {
            line: 13,
            column: 28
          },
          end: {
            line: 13,
            column: 55
          }
        }, {
          start: {
            line: 13,
            column: 59
          },
          end: {
            line: 13,
            column: 85
          }
        }, {
          start: {
            line: 13,
            column: 90
          },
          end: {
            line: 13,
            column: 112
          }
        }],
        line: 13
      },
      '2': {
        loc: {
          start: {
            line: 15,
            column: 13
          },
          end: {
            line: 17,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 15,
            column: 13
          },
          end: {
            line: 17,
            column: 7
          }
        }, {
          start: {
            line: 15,
            column: 13
          },
          end: {
            line: 17,
            column: 7
          }
        }],
        line: 15
      },
      '3': {
        loc: {
          start: {
            line: 15,
            column: 17
          },
          end: {
            line: 15,
            column: 119
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 15,
            column: 17
          },
          end: {
            line: 15,
            column: 30
          }
        }, {
          start: {
            line: 15,
            column: 35
          },
          end: {
            line: 15,
            column: 62
          }
        }, {
          start: {
            line: 15,
            column: 66
          },
          end: {
            line: 15,
            column: 92
          }
        }, {
          start: {
            line: 15,
            column: 97
          },
          end: {
            line: 15,
            column: 119
          }
        }],
        line: 15
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0, 0, 0],
      '2': [0, 0],
      '3': [0, 0, 0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ViewsController =
// eslint-disable-next-line max-params
function ViewsController($scope, $http, $rootScope, $state, myVars, ngNotify, $location, $mdDialog, PageViews, $window, $httpParamSerializerJQLike, localStorageService) {
  _classCallCheck(this, ViewsController);

  cov_2bn7jyq2r4.f[0]++;

  var self = (cov_2bn7jyq2r4.s[0]++, this);

  var islogin = (cov_2bn7jyq2r4.s[1]++, localStorageService.get('islogin'));
  cov_2bn7jyq2r4.s[2]++;
  myVars.checkMember = localStorageService.get('status');
  cov_2bn7jyq2r4.s[3]++;
  $scope.checkinfo = myVars;
  cov_2bn7jyq2r4.s[4]++;
  $scope.checkinfo.islogin = islogin;
  cov_2bn7jyq2r4.s[5]++;
  this.$onInit = function () {
    cov_2bn7jyq2r4.f[1]++;
    cov_2bn7jyq2r4.s[6]++;

    self.myScopeObject = self.clipData;
    var viewcount = (cov_2bn7jyq2r4.s[7]++, PageViews.get('views'));
    cov_2bn7jyq2r4.s[8]++;
    if ((cov_2bn7jyq2r4.b[1][0]++, viewcount > 3) && ((cov_2bn7jyq2r4.b[1][1]++, myVars.checkMember === null) || (cov_2bn7jyq2r4.b[1][2]++, myVars.checkMember === '1')) && (cov_2bn7jyq2r4.b[1][3]++, myVars.msisdn === null)) {
      cov_2bn7jyq2r4.b[0][0]++;
      cov_2bn7jyq2r4.s[9]++;

      $scope.dangkywifi();
    } else {
        cov_2bn7jyq2r4.b[0][1]++;
        cov_2bn7jyq2r4.s[10]++;
        if ((cov_2bn7jyq2r4.b[3][0]++, viewcount > 3) && ((cov_2bn7jyq2r4.b[3][1]++, myVars.checkMember === null) || (cov_2bn7jyq2r4.b[3][2]++, myVars.checkMember === '1')) && (cov_2bn7jyq2r4.b[3][3]++, myVars.msisdn !== null)) {
          cov_2bn7jyq2r4.b[2][0]++;
          cov_2bn7jyq2r4.s[11]++;

          $scope.dangky3g();
        } else {
          cov_2bn7jyq2r4.b[2][1]++;
        }
      }
  };
  cov_2bn7jyq2r4.s[12]++;
  PageViews.add('views');
  cov_2bn7jyq2r4.s[13]++;
  $scope.checkinfo = myVars;
  cov_2bn7jyq2r4.s[14]++;
  $scope.dangkywifi = function (ev) {
    cov_2bn7jyq2r4.f[2]++;

    var confirm = (cov_2bn7jyq2r4.s[15]++, $mdDialog.confirm().title('Bạn có muốn tiếp tục xem thêm tin tức ?').textContent('Quý khách đã hết lượt xem miễn phí trong ngày. Soạn DK hoặc T gửi 5060 để tiếp tục sử dụng dịch vụ').clickOutsideToClose(false).escapeToClose(false).ok('Quay lại').targetEvent(ev));
    cov_2bn7jyq2r4.s[16]++;
    $mdDialog.show(confirm).then(function () {
      cov_2bn7jyq2r4.f[3]++;
      cov_2bn7jyq2r4.s[17]++;

      $state.go('app');
    }, function () {
      cov_2bn7jyq2r4.f[4]++;
      cov_2bn7jyq2r4.s[18]++;

      $mdDialog.show();
    });
  };

  cov_2bn7jyq2r4.s[19]++;
  $scope.dangky3g = function (ev) {
    cov_2bn7jyq2r4.f[5]++;

    var confirm = (cov_2bn7jyq2r4.s[20]++, $mdDialog.confirm().title('Đăng ký dịch vụ').textContent('Quý khách đã hết lượt xem miễn phí trong ngày, click ĐỒNG Ý để đăng ký dịch vụ ').ariaLabel('Lucky day').targetEvent(ev).clickOutsideToClose(false).escapeToClose(false).ok($scope.dangky(1)).cancel('QUAY LẠI'));
    cov_2bn7jyq2r4.s[21]++;
    $mdDialog.show(confirm).then(function () {
      cov_2bn7jyq2r4.f[6]++;
      cov_2bn7jyq2r4.s[22]++;

      $scope.dangky(1);
    }, function () {
      cov_2bn7jyq2r4.f[7]++;
      cov_2bn7jyq2r4.s[23]++;

      $state.go('app');
    });
  };
  cov_2bn7jyq2r4.s[24]++;
  $scope.dangky = function (packageid) {
    cov_2bn7jyq2r4.f[8]++;
    cov_2bn7jyq2r4.s[25]++;

    $http.post($rootScope.apiOpen + 'dangky/' + packageid).then(function (results) {
      cov_2bn7jyq2r4.f[9]++;
      cov_2bn7jyq2r4.s[26]++;

      $window.location = results.data.message;
    });
  };
  // $scope.dangky = function () {
  //   $http.post($rootScope.apiOpen + 'dangky/' + myVars.msisdn).then(results => {
  //     $window.location = results.data.message;
  //   });
  // };
  // alert(PageViews.get('views'));
  // $scope.showBuy = function (ev) {
  //   const confirm = $mdDialog.confirm()
  //     .title('Bạn có muốn mua clip  (3.000 đ/lần)')
  //     .textContent('Tên clip : ' + self.myScopeObject.title)
  //     .ariaLabel('Lucky day')
  //     .targetEvent(ev)
  //     .clickOutsideToClose(true)
  //     .ok('Đồng ý !')
  //     .cancel('Không');
  //   $mdDialog.show(confirm).then(() => {
  //     const contentBuy = {
  //       msisdn: myVars.msisdn,
  //       clip: self.myScopeObject.title,
  //       link: $location.absUrl(),
  //       id: self.myScopeObject.id
  //     };
  //     if ($scope.receiver) {
  //       contentBuy.receiver = $scope.receiver;
  //     }
  //     $http({
  //       method: 'POST',
  //       url: $rootScope.apiOpen + 'clips/buy',
  //       headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  //       data: $httpParamSerializerJQLike(contentBuy)
  //     })
  //     .then(result => {
  //       $window.location.href = result.data.message;
  //     });
  //   }, () => {});
  // };
  //
  // function GiftController($scope, $mdDialog, msisdn, receiver) {
  //   $scope.title = self.myScopeObject.title;
  //   $scope.closeDialog = function () {
  //     $mdDialog.hide();
  //   };
  //   $scope.gift = function () {
  //     const contentGift = {
  //       msisdn: myVars.msisdn,
  //       receiver: $scope.receiver,
  //       clip: self.myScopeObject.title,
  //       id: self.myScopeObject.id,
  //       link: $location.absUrl(),
  //       status: true
  //     };
  //     const config = {
  //       params: contentGift,
  //       headers: {Accept: 'application/json'}
  //     };
  //     $http.get($rootScope.apiOpen + 'clips/gift', config).then(result => {
  //       if (result.data.status !== '0') {
  //         ngNotify.set('Có lỗi xảy ra , xin vui lòng thử lại sau !', {
  //           position: 'top',
  //           type: 'error',
  //           duration: 3000
  //         });
  //         $mdDialog.hide();
  //       } else {
  //         ngNotify.set('Bạn đã tặng clip thành công', {
  //           position: 'top',
  //           type: 'success',
  //           duration: 3000
  //         });
  //         $mdDialog.hide();
  //       }
  //     });
  //   };
  // }
  //
  // $scope.showPrompt = function (ev) {
  //   $mdDialog.show({
  //     controller: GiftController,
  //     templateUrl: 'app/views/prompt.html',
  //     parent: angular.element(document.body),
  //     targetEvent: ev,
  //     escapeToClose: true,
  //     locals: {
  //       clipData: self.myScopeObject.clipData,
  //       msisdn: $scope.msisdn,
  //       receiver: $scope.receiver
  //     }
  //   })
  //     .then(() => {
  //     }, () => {
  //     });
  //   $scope.$watch(() => {
  //     return $mdMedia('xs') || $mdMedia('sm');
  //   }, wantsFullScreen => {
  //     $scope.customFullscreen = (wantsFullScreen === true);
  //   });
  // };
};

cov_2bn7jyq2r4.s[27]++;


angular.module('app').component('views', {
  templateUrl: 'app/views/views.html',
  controller: ViewsController,
  bindings: {
    clipData: '<'
  }
});