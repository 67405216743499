'use strict';

var cov_2hgktebyha = function () {
  var path = 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\app\\header.js',
      hash = 'b2e16b59bdf2bd3834cd8a792f982bad5998e3dc',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\app\\header.js',
    statementMap: {
      '0': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 5,
          column: 30
        }
      },
      '1': {
        start: {
          line: 6,
          column: 4
        },
        end: {
          line: 6,
          column: 31
        }
      },
      '2': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 7,
          column: 28
        }
      },
      '3': {
        start: {
          line: 8,
          column: 4
        },
        end: {
          line: 8,
          column: 29
        }
      },
      '4': {
        start: {
          line: 9,
          column: 4
        },
        end: {
          line: 9,
          column: 43
        }
      },
      '5': {
        start: {
          line: 10,
          column: 4
        },
        end: {
          line: 10,
          column: 45
        }
      },
      '6': {
        start: {
          line: 11,
          column: 4
        },
        end: {
          line: 11,
          column: 30
        }
      },
      '7': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 18,
          column: 6
        }
      },
      '8': {
        start: {
          line: 14,
          column: 19
        },
        end: {
          line: 14,
          column: 58
        }
      },
      '9': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 17,
          column: 9
        }
      },
      '10': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 22,
          column: 7
        }
      },
      '11': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 21,
          column: 39
        }
      },
      '12': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 47
        }
      },
      '13': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 26,
          column: 41
        }
      },
      '14': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 30,
          column: 49
        }
      },
      '15': {
        start: {
          line: 35,
          column: 0
        },
        end: {
          line: 40,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 4,
            column: 2
          },
          end: {
            line: 4,
            column: 3
          }
        },
        loc: {
          start: {
            line: 4,
            column: 97
          },
          end: {
            line: 32,
            column: 3
          }
        },
        line: 4
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 12,
            column: 21
          },
          end: {
            line: 12,
            column: 22
          }
        },
        loc: {
          start: {
            line: 12,
            column: 33
          },
          end: {
            line: 18,
            column: 5
          }
        },
        line: 12
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 20,
            column: 77
          },
          end: {
            line: 20,
            column: 78
          }
        },
        loc: {
          start: {
            line: 20,
            column: 88
          },
          end: {
            line: 22,
            column: 5
          }
        },
        line: 20
      },
      '3': {
        name: 'toggleCollapse',
        decl: {
          start: {
            line: 24,
            column: 13
          },
          end: {
            line: 24,
            column: 27
          }
        },
        loc: {
          start: {
            line: 24,
            column: 30
          },
          end: {
            line: 27,
            column: 5
          }
        },
        line: 24
      },
      '4': {
        name: 'toggleCollapse2',
        decl: {
          start: {
            line: 29,
            column: 13
          },
          end: {
            line: 29,
            column: 28
          }
        },
        loc: {
          start: {
            line: 29,
            column: 31
          },
          end: {
            line: 31,
            column: 5
          }
        },
        line: 29
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var HeaderController =
/** @ngInject */
// eslint-disable-next-line max-params
function HeaderController($scope, $http, $state, $rootScope, ngNotify, myVars, localStorageService, $filter) {
  _classCallCheck(this, HeaderController);

  cov_2hgktebyha.f[0]++;
  cov_2hgktebyha.s[0]++;

  $scope.isCollapsed = true;
  cov_2hgktebyha.s[1]++;
  $scope.isCollapsed2 = true;
  cov_2hgktebyha.s[2]++;
  $scope.showmenu = false;
  cov_2hgktebyha.s[3]++;
  $scope.showmenu2 = false;
  cov_2hgktebyha.s[4]++;
  $scope.toggleCollapse = toggleCollapse;
  cov_2hgktebyha.s[5]++;
  $scope.toggleCollapse2 = toggleCollapse2;
  cov_2hgktebyha.s[6]++;
  $scope.checkinfo = myVars;
  cov_2hgktebyha.s[7]++;
  $scope.searchL = function () {
    cov_2hgktebyha.f[1]++;

    // eslint-disable-next-line no-unused-vars
    var text = (cov_2hgktebyha.s[8]++, $filter('urlEncode')($scope.textsearch));
    cov_2hgktebyha.s[9]++;
    $state.go('search', {
      title: $scope.textsearch
    });
  };
  // get xem nhieu nhat
  cov_2hgktebyha.s[10]++;
  $http.post($rootScope.apiOpen + 'categories/list/' + $scope.msisdn).then(function (results) {
    cov_2hgktebyha.f[2]++;
    cov_2hgktebyha.s[11]++;

    $scope.categories = results.data;
  });

  function toggleCollapse() {
    cov_2hgktebyha.f[3]++;
    cov_2hgktebyha.s[12]++;

    $scope.isCollapsed = !$scope.isCollapsed;
    cov_2hgktebyha.s[13]++;
    $scope.showmenu = !$scope.showmenu;
  }

  function toggleCollapse2() {
    cov_2hgktebyha.f[4]++;
    cov_2hgktebyha.s[14]++;

    $scope.isCollapsed2 = !$scope.isCollapsed2;
  }
};

cov_2hgktebyha.s[15]++;


angular.module('app').component('fountainHeader', {
  templateUrl: 'app/header.html',
  controller: HeaderController
});