'use strict';

var cov_gfchbokg4 = function () {
  var path = 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\app\\right\\righ.js',
      hash = 'bf4b5a4c2609deebeb0e1cf32f71b0419fbcc8e9',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\app\\right\\righ.js',
    statementMap: {
      '0': {
        start: {
          line: 3,
          column: 4
        },
        end: {
          line: 3,
          column: 42
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 6,
          column: 7
        }
      },
      '2': {
        start: {
          line: 5,
          column: 6
        },
        end: {
          line: 5,
          column: 38
        }
      },
      '3': {
        start: {
          line: 10,
          column: 0
        },
        end: {
          line: 15,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 2
          },
          end: {
            line: 2,
            column: 3
          }
        },
        loc: {
          start: {
            line: 2,
            column: 55
          },
          end: {
            line: 7,
            column: 3
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 4,
            column: 57
          },
          end: {
            line: 4,
            column: 58
          }
        },
        loc: {
          start: {
            line: 4,
            column: 68
          },
          end: {
            line: 6,
            column: 5
          }
        },
        line: 4
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var RighController = function RighController($scope, $rootScope, $http, $stateParams) {
  _classCallCheck(this, RighController);

  cov_gfchbokg4.f[0]++;
  cov_gfchbokg4.s[0]++;

  this.text = 'My brand new component!';
  cov_gfchbokg4.s[1]++;
  $http.get($rootScope.apiOpen + 'clips/getView').then(function (results) {
    cov_gfchbokg4.f[1]++;
    cov_gfchbokg4.s[2]++;

    $scope.orderView = results.data;
  });
};

cov_gfchbokg4.s[3]++;


angular.module('app').component('righ', {
  templateUrl: 'app/right/righ.html',
  controller: RighController
});