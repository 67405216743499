'use strict';

var cov_28w81pg1wi = function () {
  var path = 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\app\\main.js',
      hash = '85dbc9467f1f08b770489dbbe834ba687d31ef78',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\app\\main.js',
    statementMap: {
      '0': {
        start: {
          line: 6,
          column: 4
        },
        end: {
          line: 6,
          column: 42
        }
      },
      '1': {
        start: {
          line: 9,
          column: 0
        },
        end: {
          line: 17,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 5,
            column: 2
          },
          end: {
            line: 5,
            column: 3
          }
        },
        loc: {
          start: {
            line: 5,
            column: 16
          },
          end: {
            line: 7,
            column: 3
          }
        },
        line: 5
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MainController =
/** @ngInject */
// eslint-disable-next-line max-params
function MainController() {
  _classCallCheck(this, MainController);

  cov_28w81pg1wi.f[0]++;
  cov_28w81pg1wi.s[0]++;

  this.text = 'My brand new component!';
};

cov_28w81pg1wi.s[1]++;

angular.module('app').component('app', {
  templateUrl: 'app/main.html',
  controller: MainController,
  bindings: {
    clipsData: '<'
  }
});