'use strict';

var cov_7kno11mi3 = function () {
  var path = 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\app\\categories\\categories.js',
      hash = 'a857b34aaa710ec83d827eda940ab9a458e47784',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\app\\categories\\categories.js',
    statementMap: {
      '0': {
        start: {
          line: 3,
          column: 4
        },
        end: {
          line: 3,
          column: 45
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 6,
          column: 7
        }
      },
      '2': {
        start: {
          line: 5,
          column: 6
        },
        end: {
          line: 5,
          column: 39
        }
      },
      '3': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 11,
          column: 6
        }
      },
      '4': {
        start: {
          line: 12,
          column: 4
        },
        end: {
          line: 12,
          column: 27
        }
      },
      '5': {
        start: {
          line: 14,
          column: 4
        },
        end: {
          line: 36,
          column: 6
        }
      },
      '6': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 17,
          column: 7
        }
      },
      '7': {
        start: {
          line: 16,
          column: 8
        },
        end: {
          line: 16,
          column: 15
        }
      },
      '8': {
        start: {
          line: 18,
          column: 6
        },
        end: {
          line: 20,
          column: 7
        }
      },
      '9': {
        start: {
          line: 19,
          column: 8
        },
        end: {
          line: 19,
          column: 15
        }
      },
      '10': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 22,
          column: 41
        }
      },
      '11': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 87
        }
      },
      '12': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 56
        }
      },
      '13': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 34
        }
      },
      '14': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 34,
          column: 9
        }
      },
      '15': {
        start: {
          line: 28,
          column: 8
        },
        end: {
          line: 28,
          column: 41
        }
      },
      '16': {
        start: {
          line: 29,
          column: 8
        },
        end: {
          line: 33,
          column: 9
        }
      },
      '17': {
        start: {
          line: 30,
          column: 10
        },
        end: {
          line: 32,
          column: 11
        }
      },
      '18': {
        start: {
          line: 31,
          column: 12
        },
        end: {
          line: 31,
          column: 113
        }
      },
      '19': {
        start: {
          line: 35,
          column: 6
        },
        end: {
          line: 35,
          column: 35
        }
      },
      '20': {
        start: {
          line: 38,
          column: 4
        },
        end: {
          line: 44,
          column: 6
        }
      },
      '21': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 43,
          column: 9
        }
      },
      '22': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 40,
          column: 36
        }
      },
      '23': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 41,
          column: 91
        }
      },
      '24': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 42,
          column: 33
        }
      },
      '25': {
        start: {
          line: 46,
          column: 4
        },
        end: {
          line: 46,
          column: 34
        }
      },
      '26': {
        start: {
          line: 48,
          column: 4
        },
        end: {
          line: 50,
          column: 7
        }
      },
      '27': {
        start: {
          line: 49,
          column: 6
        },
        end: {
          line: 49,
          column: 38
        }
      },
      '28': {
        start: {
          line: 53,
          column: 0
        },
        end: {
          line: 58,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 2
          },
          end: {
            line: 2,
            column: 3
          }
        },
        loc: {
          start: {
            line: 2,
            column: 55
          },
          end: {
            line: 51,
            column: 3
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 4,
            column: 76
          },
          end: {
            line: 4,
            column: 77
          }
        },
        loc: {
          start: {
            line: 4,
            column: 87
          },
          end: {
            line: 6,
            column: 5
          }
        },
        line: 4
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 14,
            column: 29
          },
          end: {
            line: 14,
            column: 30
          }
        },
        loc: {
          start: {
            line: 14,
            column: 41
          },
          end: {
            line: 36,
            column: 5
          }
        },
        line: 14
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 27,
            column: 121
          },
          end: {
            line: 27,
            column: 122
          }
        },
        loc: {
          start: {
            line: 27,
            column: 132
          },
          end: {
            line: 34,
            column: 7
          }
        },
        line: 27
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 38,
            column: 34
          },
          end: {
            line: 38,
            column: 35
          }
        },
        loc: {
          start: {
            line: 38,
            column: 46
          },
          end: {
            line: 44,
            column: 5
          }
        },
        line: 38
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 39,
            column: 82
          },
          end: {
            line: 39,
            column: 83
          }
        },
        loc: {
          start: {
            line: 39,
            column: 93
          },
          end: {
            line: 43,
            column: 7
          }
        },
        line: 39
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 48,
            column: 59
          },
          end: {
            line: 48,
            column: 60
          }
        },
        loc: {
          start: {
            line: 48,
            column: 70
          },
          end: {
            line: 50,
            column: 5
          }
        },
        line: 48
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 15,
            column: 6
          },
          end: {
            line: 17,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 15,
            column: 6
          },
          end: {
            line: 17,
            column: 7
          }
        }, {
          start: {
            line: 15,
            column: 6
          },
          end: {
            line: 17,
            column: 7
          }
        }],
        line: 15
      },
      '1': {
        loc: {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        }, {
          start: {
            line: 18,
            column: 6
          },
          end: {
            line: 20,
            column: 7
          }
        }],
        line: 18
      },
      '2': {
        loc: {
          start: {
            line: 30,
            column: 10
          },
          end: {
            line: 32,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 30,
            column: 10
          },
          end: {
            line: 32,
            column: 11
          }
        }, {
          start: {
            line: 30,
            column: 10
          },
          end: {
            line: 32,
            column: 11
          }
        }],
        line: 30
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CategoriesController = function CategoriesController($scope, $rootScope, $http, $stateParams) {
  _classCallCheck(this, CategoriesController);

  cov_7kno11mi3.f[0]++;
  cov_7kno11mi3.s[0]++;

  $scope.cateid = $stateParams.categorieid;
  cov_7kno11mi3.s[1]++;
  $http.get($rootScope.apiOpen + 'categories/find/' + $scope.cateid).then(function (results) {
    cov_7kno11mi3.f[1]++;
    cov_7kno11mi3.s[2]++;

    $scope.categoties = results.data;
  });
  cov_7kno11mi3.s[3]++;
  $scope.pagination = {
    noOfPages: 1,
    currentPage: 0,
    pageSize: 12
  };
  cov_7kno11mi3.s[4]++;
  $scope.resultList = [];
  // $scope.loadingResult = true;
  cov_7kno11mi3.s[5]++;
  $scope.loadMoreCourses = function () {
    cov_7kno11mi3.f[2]++;
    cov_7kno11mi3.s[6]++;

    if ($scope.loadingResult) {
      cov_7kno11mi3.b[0][0]++;
      cov_7kno11mi3.s[7]++;

      return;
    } else {
      cov_7kno11mi3.b[0][1]++;
    }
    cov_7kno11mi3.s[8]++;
    if ($scope.pagination.currentPage >= $scope.pagination.noOfPages) {
      cov_7kno11mi3.b[1][0]++;
      cov_7kno11mi3.s[9]++;

      return;
    } else {
      cov_7kno11mi3.b[1][1]++;
    }

    cov_7kno11mi3.s[10]++;
    $scope.pagination.currentPage += 1;
    cov_7kno11mi3.s[11]++;
    $scope.offset = ($scope.pagination.currentPage - 1) * $scope.pagination.pageSize;
    cov_7kno11mi3.s[12]++;
    $scope.limit = $scope.pagination.currentPage * 12;
    cov_7kno11mi3.s[13]++;
    $scope.loadingResult = true;
    // $scope.resultList = CourseService.courses.list({offset:$scope.offset, limit:$scope.limit});
    cov_7kno11mi3.s[14]++;
    $http.get($rootScope.apiOpen + 'article/getPage/' + $scope.cateid + '/' + $scope.offset + '/' + $scope.limit).then(function (results) {
      cov_7kno11mi3.f[3]++;
      cov_7kno11mi3.s[15]++;

      $scope.resultList = results.data;
      cov_7kno11mi3.s[16]++;
      for (var i = 0; i < $scope.resultList.length; i++) {
        cov_7kno11mi3.s[17]++;

        if ($scope.resultList[i].dateactive != null) {
          cov_7kno11mi3.b[2][0]++;
          cov_7kno11mi3.s[18]++;

          $scope.resultList[i].dateactive = moment($scope.resultList[i].dateactive).format('DD/MM/YYYY hh:mm');
        } else {
          cov_7kno11mi3.b[2][1]++;
        }
      }
    });
    cov_7kno11mi3.s[19]++;
    $scope.loadingResult = false;
  };

  cov_7kno11mi3.s[20]++;
  $scope.initializeResultList = function () {
    cov_7kno11mi3.f[4]++;
    cov_7kno11mi3.s[21]++;

    $http.get($rootScope.apiOpen + 'article/countByCate/' + $scope.cateid).then(function (results) {
      cov_7kno11mi3.f[5]++;
      cov_7kno11mi3.s[22]++;

      $scope.total = results.data;
      cov_7kno11mi3.s[23]++;
      $scope.pagination.noOfPages = Math.ceil(results.data / $scope.pagination.pageSize);
      cov_7kno11mi3.s[24]++;
      $scope.loadMoreCourses();
    });
  };

  cov_7kno11mi3.s[25]++;
  $scope.initializeResultList();

  cov_7kno11mi3.s[26]++;
  $http.get($rootScope.apiOpen + 'article/getView').then(function (results) {
    cov_7kno11mi3.f[6]++;
    cov_7kno11mi3.s[27]++;

    $scope.orderView = results.data;
  });
};

cov_7kno11mi3.s[28]++;

angular.module('app').component('categories', {
  templateUrl: 'app/categories/categories.html',
  controller: CategoriesController
});