'use strict';

var cov_2mk66qhdys = function () {
  var path = 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\index.js',
      hash = '31df7bd31c0568629e9c99920e898ede7e8bc04c',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: 'D:\\19\\source\\19-ngoisao.ansovang.vn\\ngoisao_client_news\\src\\index.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 133,
          column: 6
        }
      },
      '1': {
        start: {
          line: 11,
          column: 4
        },
        end: {
          line: 11,
          column: 28
        }
      },
      '2': {
        start: {
          line: 15,
          column: 4
        },
        end: {
          line: 33,
          column: 6
        }
      },
      '3': {
        start: {
          line: 17,
          column: 6
        },
        end: {
          line: 19,
          column: 7
        }
      },
      '4': {
        start: {
          line: 18,
          column: 8
        },
        end: {
          line: 18,
          column: 15
        }
      },
      '5': {
        start: {
          line: 21,
          column: 6
        },
        end: {
          line: 21,
          column: 18
        }
      },
      '6': {
        start: {
          line: 22,
          column: 6
        },
        end: {
          line: 22,
          column: 30
        }
      },
      '7': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 67
        }
      },
      '8': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 55
        }
      },
      '9': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 43
        }
      },
      '10': {
        start: {
          line: 26,
          column: 6
        },
        end: {
          line: 26,
          column: 67
        }
      },
      '11': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 27,
          column: 55
        }
      },
      '12': {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 28,
          column: 43
        }
      },
      '13': {
        start: {
          line: 29,
          column: 6
        },
        end: {
          line: 29,
          column: 35
        }
      },
      '14': {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 31,
          column: 115
        }
      },
      '15': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 32,
          column: 17
        }
      },
      '16': {
        start: {
          line: 36,
          column: 4
        },
        end: {
          line: 44,
          column: 6
        }
      },
      '17': {
        start: {
          line: 37,
          column: 18
        },
        end: {
          line: 37,
          column: 24
        }
      },
      '18': {
        start: {
          line: 39,
          column: 6
        },
        end: {
          line: 43,
          column: 9
        }
      },
      '19': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 42,
          column: 9
        }
      },
      '20': {
        start: {
          line: 41,
          column: 10
        },
        end: {
          line: 41,
          column: 40
        }
      },
      '21': {
        start: {
          line: 48,
          column: 4
        },
        end: {
          line: 48,
          column: 52
        }
      },
      '22': {
        start: {
          line: 49,
          column: 4
        },
        end: {
          line: 75,
          column: 6
        }
      },
      '23': {
        start: {
          line: 51,
          column: 8
        },
        end: {
          line: 70,
          column: 9
        }
      },
      '24': {
        start: {
          line: 52,
          column: 22
        },
        end: {
          line: 52,
          column: 61
        }
      },
      '25': {
        start: {
          line: 53,
          column: 10
        },
        end: {
          line: 69,
          column: 11
        }
      },
      '26': {
        start: {
          line: 54,
          column: 23
        },
        end: {
          line: 54,
          column: 56
        }
      },
      '27': {
        start: {
          line: 56,
          column: 24
        },
        end: {
          line: 56,
          column: 51
        }
      },
      '28': {
        start: {
          line: 60,
          column: 12
        },
        end: {
          line: 65,
          column: 13
        }
      },
      '29': {
        start: {
          line: 61,
          column: 14
        },
        end: {
          line: 61,
          column: 58
        }
      },
      '30': {
        start: {
          line: 63,
          column: 14
        },
        end: {
          line: 63,
          column: 52
        }
      },
      '31': {
        start: {
          line: 64,
          column: 14
        },
        end: {
          line: 64,
          column: 89
        }
      },
      '32': {
        start: {
          line: 67,
          column: 12
        },
        end: {
          line: 67,
          column: 56
        }
      },
      '33': {
        start: {
          line: 68,
          column: 12
        },
        end: {
          line: 68,
          column: 87
        }
      },
      '34': {
        start: {
          line: 73,
          column: 8
        },
        end: {
          line: 73,
          column: 50
        }
      },
      '35': {
        start: {
          line: 78,
          column: 4
        },
        end: {
          line: 119,
          column: 6
        }
      },
      '36': {
        start: {
          line: 82,
          column: 8
        },
        end: {
          line: 117,
          column: 10
        }
      },
      '37': {
        start: {
          line: 84,
          column: 33
        },
        end: {
          line: 95,
          column: 11
        }
      },
      '38': {
        start: {
          line: 97,
          column: 32
        },
        end: {
          line: 97,
          column: 76
        }
      },
      '39': {
        start: {
          line: 100,
          column: 10
        },
        end: {
          line: 113,
          column: 11
        }
      },
      '40': {
        start: {
          line: 101,
          column: 12
        },
        end: {
          line: 101,
          column: 53
        }
      },
      '41': {
        start: {
          line: 102,
          column: 12
        },
        end: {
          line: 112,
          column: 14
        }
      },
      '42': {
        start: {
          line: 116,
          column: 10
        },
        end: {
          line: 116,
          column: 49
        }
      },
      '43': {
        start: {
          line: 123,
          column: 4
        },
        end: {
          line: 132,
          column: 6
        }
      },
      '44': {
        start: {
          line: 128,
          column: 8
        },
        end: {
          line: 130,
          column: 9
        }
      },
      '45': {
        start: {
          line: 129,
          column: 10
        },
        end: {
          line: 129,
          column: 47
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 10,
            column: 20
          },
          end: {
            line: 10,
            column: 21
          }
        },
        loc: {
          start: {
            line: 10,
            column: 28
          },
          end: {
            line: 12,
            column: 3
          }
        },
        line: 10
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 14,
            column: 24
          },
          end: {
            line: 14,
            column: 25
          }
        },
        loc: {
          start: {
            line: 14,
            column: 36
          },
          end: {
            line: 34,
            column: 3
          }
        },
        line: 14
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 11
          },
          end: {
            line: 15,
            column: 12
          }
        },
        loc: {
          start: {
            line: 15,
            column: 28
          },
          end: {
            line: 33,
            column: 5
          }
        },
        line: 15
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 35,
            column: 27
          },
          end: {
            line: 35,
            column: 28
          }
        },
        loc: {
          start: {
            line: 35,
            column: 33
          },
          end: {
            line: 45,
            column: 3
          }
        },
        line: 35
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 36,
            column: 11
          },
          end: {
            line: 36,
            column: 12
          }
        },
        loc: {
          start: {
            line: 36,
            column: 39
          },
          end: {
            line: 44,
            column: 5
          }
        },
        line: 36
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 39,
            column: 25
          },
          end: {
            line: 39,
            column: 26
          }
        },
        loc: {
          start: {
            line: 39,
            column: 31
          },
          end: {
            line: 43,
            column: 7
          }
        },
        line: 39
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 47,
            column: 59
          },
          end: {
            line: 47,
            column: 60
          }
        },
        loc: {
          start: {
            line: 47,
            column: 99
          },
          end: {
            line: 76,
            column: 3
          }
        },
        line: 47
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 77,
            column: 28
          },
          end: {
            line: 77,
            column: 29
          }
        },
        loc: {
          start: {
            line: 77,
            column: 34
          },
          end: {
            line: 120,
            column: 3
          }
        },
        line: 77
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 82,
            column: 29
          },
          end: {
            line: 82,
            column: 30
          }
        },
        loc: {
          start: {
            line: 82,
            column: 48
          },
          end: {
            line: 117,
            column: 9
          }
        },
        line: 82
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 122,
            column: 33
          },
          end: {
            line: 122,
            column: 34
          }
        },
        loc: {
          start: {
            line: 122,
            column: 45
          },
          end: {
            line: 133,
            column: 3
          }
        },
        line: 122
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }, {
          start: {
            line: 17,
            column: 6
          },
          end: {
            line: 19,
            column: 7
          }
        }],
        line: 17
      },
      '1': {
        loc: {
          start: {
            line: 40,
            column: 8
          },
          end: {
            line: 42,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 40,
            column: 8
          },
          end: {
            line: 42,
            column: 9
          }
        }, {
          start: {
            line: 40,
            column: 8
          },
          end: {
            line: 42,
            column: 9
          }
        }],
        line: 40
      },
      '2': {
        loc: {
          start: {
            line: 48,
            column: 25
          },
          end: {
            line: 48,
            column: 51
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 48,
            column: 25
          },
          end: {
            line: 48,
            column: 43
          }
        }, {
          start: {
            line: 48,
            column: 47
          },
          end: {
            line: 48,
            column: 51
          }
        }],
        line: 48
      },
      '3': {
        loc: {
          start: {
            line: 51,
            column: 8
          },
          end: {
            line: 70,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 51,
            column: 8
          },
          end: {
            line: 70,
            column: 9
          }
        }, {
          start: {
            line: 51,
            column: 8
          },
          end: {
            line: 70,
            column: 9
          }
        }],
        line: 51
      },
      '4': {
        loc: {
          start: {
            line: 52,
            column: 22
          },
          end: {
            line: 52,
            column: 61
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 52,
            column: 22
          },
          end: {
            line: 52,
            column: 56
          }
        }, {
          start: {
            line: 52,
            column: 60
          },
          end: {
            line: 52,
            column: 61
          }
        }],
        line: 52
      },
      '5': {
        loc: {
          start: {
            line: 53,
            column: 10
          },
          end: {
            line: 69,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 53,
            column: 10
          },
          end: {
            line: 69,
            column: 11
          }
        }, {
          start: {
            line: 53,
            column: 10
          },
          end: {
            line: 69,
            column: 11
          }
        }],
        line: 53
      },
      '6': {
        loc: {
          start: {
            line: 60,
            column: 12
          },
          end: {
            line: 65,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 60,
            column: 12
          },
          end: {
            line: 65,
            column: 13
          }
        }, {
          start: {
            line: 60,
            column: 12
          },
          end: {
            line: 65,
            column: 13
          }
        }],
        line: 60
      },
      '7': {
        loc: {
          start: {
            line: 128,
            column: 8
          },
          end: {
            line: 130,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 128,
            column: 8
          },
          end: {
            line: 130,
            column: 9
          }
        }, {
          start: {
            line: 128,
            column: 8
          },
          end: {
            line: 130,
            column: 9
          }
        }],
        line: 128
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2mk66qhdys.s[0]++;
angular.module('app', ['ui.router', 'ui.bootstrap', 'ngAnimate', 'ui.carousel', 'ngMessages', 'angularMoment', 'timer', 'angularjs-crypto', 'ngMaterial', 'ngNotify', 'LocalStorageModule']).value('myVars', {
  msisdn: null,
  checkMember: null,
  login: 0,
  packageid: null
}).filter('unsafe', function ($sce) {
  cov_2mk66qhdys.f[0]++;
  cov_2mk66qhdys.s[1]++;

  return $sce.trustAsHtml;
})
// eslint-disable-next-line angular/di
.filter('urlEncode', [function () {
  cov_2mk66qhdys.f[1]++;
  cov_2mk66qhdys.s[2]++;

  return function (input) {
    cov_2mk66qhdys.f[2]++;
    cov_2mk66qhdys.s[3]++;

    // eslint-disable-next-line angular/definedundefined
    if (typeof input === 'undefined') {
      cov_2mk66qhdys.b[0][0]++;
      cov_2mk66qhdys.s[4]++;

      return;
    } else {
      cov_2mk66qhdys.b[0][1]++;
    }
    var str = void 0;
    cov_2mk66qhdys.s[5]++;
    str = input;
    cov_2mk66qhdys.s[6]++;
    str = str.toLowerCase();
    cov_2mk66qhdys.s[7]++;
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    cov_2mk66qhdys.s[8]++;
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    cov_2mk66qhdys.s[9]++;
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    cov_2mk66qhdys.s[10]++;
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    cov_2mk66qhdys.s[11]++;
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    cov_2mk66qhdys.s[12]++;
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    cov_2mk66qhdys.s[13]++;
    str = str.replace(/đ/g, 'd');
    // eslint-disable-next-line no-useless-escape
    cov_2mk66qhdys.s[14]++;
    str = str.replace(/[\&]/g, '-and-').replace(/[^a-zA-Z0-9._-]/g, '-').replace(/[-]+/g, '-').replace(/-$/, '');
    cov_2mk66qhdys.s[15]++;
    return str;
  };
}]).directive('pvScrolled', function () {
  cov_2mk66qhdys.f[3]++;
  cov_2mk66qhdys.s[16]++;

  return function (scope, elm, attr) {
    cov_2mk66qhdys.f[4]++;

    var raw = (cov_2mk66qhdys.s[17]++, elm[0]);

    cov_2mk66qhdys.s[18]++;
    elm.bind('scroll', function () {
      cov_2mk66qhdys.f[5]++;
      cov_2mk66qhdys.s[19]++;

      if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) {
        cov_2mk66qhdys.b[1][0]++;
        cov_2mk66qhdys.s[20]++;

        scope.$apply(attr.pvScrolled);
      } else {
        cov_2mk66qhdys.b[1][1]++;
      }
    });
  };
})
// eslint-disable-next-line angular/di
.factory('PageViews', ['localStorageService', '$filter', function (localStorageService, $filter) {
  cov_2mk66qhdys.f[6]++;
  cov_2mk66qhdys.s[21]++;

  localStorage.views = (cov_2mk66qhdys.b[2][0]++, localStorage.views) || (cov_2mk66qhdys.b[2][1]++, '{}');
  cov_2mk66qhdys.s[22]++;
  return {
    add: function add(stateName) {
      cov_2mk66qhdys.s[23]++;

      if (localStorageService.isSupported) {
        cov_2mk66qhdys.b[3][0]++;

        var views = (cov_2mk66qhdys.s[24]++, (cov_2mk66qhdys.b[4][0]++, localStorageService.get(stateName)) || (cov_2mk66qhdys.b[4][1]++, 0));
        cov_2mk66qhdys.s[25]++;
        if (localStorageService.get('expire')) {
          cov_2mk66qhdys.b[5][0]++;

          var dt = (cov_2mk66qhdys.s[26]++, localStorageService.get('expire'));
          // eslint-disable-next-line no-undef
          var dt2 = (cov_2mk66qhdys.s[27]++, moment().format('YYYYMMDD'));
          // const check1 = moment(dt).isSame(moment().format('YYYYMMDD'));
          //
          // const check = moment(localStorageService.get('expire'), 'YYYYMMDD').fromNow();
          cov_2mk66qhdys.s[28]++;
          if (dt === dt2) {
            cov_2mk66qhdys.b[6][0]++;
            cov_2mk66qhdys.s[29]++;

            localStorageService.set(stateName, ++views);
          } else {
            cov_2mk66qhdys.b[6][1]++;
            cov_2mk66qhdys.s[30]++;

            localStorageService.set(stateName, 1);
            cov_2mk66qhdys.s[31]++;
            localStorageService.set('expire', $filter('date')(new Date(), 'yyyyMMdd'));
          }
        } else {
          cov_2mk66qhdys.b[5][1]++;
          cov_2mk66qhdys.s[32]++;

          localStorageService.set(stateName, ++views);
          cov_2mk66qhdys.s[33]++;
          localStorageService.set('expire', $filter('date')(new Date(), 'yyyyMMdd'));
        }
      } else {
        cov_2mk66qhdys.b[3][1]++;
      }
    },
    get: function get(stateName) {
      cov_2mk66qhdys.s[34]++;

      return localStorageService.get(stateName);
    }
  };
}]).directive('owlCarousel', function () {
  cov_2mk66qhdys.f[7]++;
  cov_2mk66qhdys.s[35]++;

  return {
    restrict: 'E',
    transclude: false,
    link: function link(scope) {
      cov_2mk66qhdys.s[36]++;

      scope.initCarousel = function (element) {
        cov_2mk66qhdys.f[8]++;

        // provide any default options you want
        var defaultOptions = (cov_2mk66qhdys.s[37]++, {
          autoPlay: false,
          pagination: false,
          slideSpeed: 250,
          transitionStyle: 'fade',
          navigation: true,
          responsiveRefreshRate: 0,
          navigationText: ['<i class=\'fa fa-chevron-left\'></i>', '<i class=\'fa fa-chevron-right\'></i>']
        });
        // eslint-disable-next-line no-undef
        var customOptions = (cov_2mk66qhdys.s[38]++, scope.$eval($(element).attr('data-options')));
        // combine the two options objects
        // eslint-disable-next-line guard-for-in
        cov_2mk66qhdys.s[39]++;
        for (var key in customOptions) {
          cov_2mk66qhdys.s[40]++;

          defaultOptions[key] = customOptions[key];
          cov_2mk66qhdys.s[41]++;
          defaultOptions.responsive = {
            0: {
              items: 1
            },
            600: {
              items: 3
            },
            1000: {
              items: 6
            }
          };
        }
        // init carousel
        // eslint-disable-next-line no-undef
        cov_2mk66qhdys.s[42]++;
        $(element).owlCarousel(defaultOptions);
      };
    }
  };
})
// eslint-disable-next-line angular/di
.directive('owlCarouselItem', [function () {
  cov_2mk66qhdys.f[9]++;
  cov_2mk66qhdys.s[43]++;

  return {
    restrict: 'A',
    transclude: false,
    link: function link(scope, element) {
      cov_2mk66qhdys.s[44]++;

      // wait for the last item in the ng-repeat then call init
      if (scope.$last) {
        cov_2mk66qhdys.b[7][0]++;
        cov_2mk66qhdys.s[45]++;

        scope.initCarousel(element.parent());
      } else {
        cov_2mk66qhdys.b[7][1]++;
      }
    }
  };
}]);
// .config(['$qProvider', function ($qProvider) {
//   $qProvider.errorOnUnhandledRejections(false);
// }])

//   .value('apiOpen', 'http://daugiacongnghe.vn/api/rest/open/')
// .value('apiSec', 'http://daugiacongnghe.vn/api/rest/secure/');

// .value('apiOpen', 'http://localhost:6500/rest/open/')
// .value('apiSec', 'http://localhost:6500/rest/secure/');
// $scope.baseUrl = '$location.host()
// .value('apiOpen', $browser.baseHref() + 'http://10.58.49.139:8090/api/rest/open/')
// .value('apiUrl', 'http://10.58.49.139:8090/api/')
// .value('apiSec', 'http://10.58.49.139:8090/api/rest/secure/');